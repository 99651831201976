import { Rating } from "@mui/material";
import "./style.css";

interface ISingleSkill {
  skillName: string;
  starCount: number;
}

const SingleSkill: React.FC<ISingleSkill> = ({ skillName, starCount }) => {
  return (
    <div className="description-box grid">
      <span className="description-box-header">{skillName}</span>
      <Rating className="skill" name="read-only" value={starCount} readOnly precision={0.5} />
    </div>
  );
};

export default SingleSkill;
