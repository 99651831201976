import { useTranslation } from 'react-i18next';
import "./style.css";

function Profile() {
  const { t } = useTranslation();

  return (
    <div>
      <div className={"nav-spacer"}></div>
      <div className={"content"}>
        <h1 className={"content-header"}>{t('profile')}</h1>
        <hr className={"content-underline"} color="#CECED2"></hr>
        <div className="container profile-container">
          <div className="box margin-top-50 text-content-card-height">
            <div className={"card"}>
              <img
                className={"full-image"}
                src="profile-picture.png"
                alt={t('professionalProfile')}
              ></img>
            </div>
          </div>
          <div className="box margin-top-50 text-content-card-height">
            <div className="text-content-card-height">
              <span className="subheader">{t('shortSummary')}</span>
              <br></br>
              {t('shortSummaryText')}
              <br></br>
              <br></br>
              <span className="subheader">{t('education.title')}</span>
              <br></br>
              <div className="grid-container-education">
                <div className="grid-item-education text-align-left">
                  {t('education.bachelor.duration')}
                </div>
                <div className="grid-item-education">
                  {t('education.bachelor.degree')}
                  <br></br>
                  {t('education.bachelor.university')}
                </div>
                <div className="grid-item-education text-align-left">
                  {t('education.minor.duration')}{" "}
                </div>
                <div className="grid-item-education">
                  {t('education.minor.degree')}
                  <br></br>
                  {t('education.minor.university')}
                </div>
                <div className="grid-item-education text-align-left">
                  {t('education.abitur.duration')}
                </div>
                <div className="grid-item-education">
                  {t('education.abitur.degree')}
                  <br></br>
                  {t('education.abitur.school')}
                </div>
                <div className="grid-item-education text-align-left">
                  {t('education.exchangeStudent.duration')}
                </div>
                <div className="grid-item-education">
                  {t('education.exchangeStudent.description')}
                  <br></br>
                  {t('education.exchangeStudent.school')}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Profile;
