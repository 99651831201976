import React, { useRef, useState } from "react";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { TweenMax, Power3 } from "gsap";

import { MobileNavProps } from "./types";
import "./styles/style.css";
import Projects from "../content/projects";
import Skills from "../content/skills";
import Work from "../content/work";
import ElementsWrapper from "./menu/elementwrapper";
import MobileCertificates from "../content/mobilecertificates";
import Footer from "../content/footer";
import Profile from "../content/profile";
import LanguageSwitcher from "../languageswitch";

const MobileNav: React.FC<MobileNavProps> = ({
  width,
  background,
  navLinks,
  socialIcons,
  sticky,
}) => {
  let nav = useRef(null);
  const [click, setClick] = useState(false);
  const socialLinks = socialIcons.map((icon: any, index: any) => (
    <li key={index}>
      <a target="_blank" href={icon.url} rel="noopener noreferrer">
        <FontAwesomeIcon icon={icon.icon} />
      </a>
    </li>
  ));
  const handelExpand = () => {
    if (click === false) {
      TweenMax.to(nav, 0.8, { height: 300, paddingBottom: 16, ease: Power3.easeOut });
      setClick(true);
    } else {
      TweenMax.to(nav, 0.8, { height: 0, paddingBottom: 0, ease: Power3.easeOut });
      setClick(false);
    }
  };

  return (
    <div
      style={{
        minHeight: "100vh",
        width: "100%",
        backgroundColor: "rgb(245, 245, 247)",
      }}
    >
      <div
        className={"MoNavContainer"}
        style={{
          background: background,
          position: sticky ? "fixed" : "unset",
          zIndex: 99,
          width: "100%",
        }}
      >
        <div className={"mobileNav"} style={{ background: background }}>
          <div className={"navBars"}>
            <FontAwesomeIcon icon={faBars} onClick={handelExpand} />
            <LanguageSwitcher></LanguageSwitcher>
          </div>
          <div className={"MoNavLogo"}>
            <img
              style={{
                filter: "invert(100%)",
              }}
              src={"logo192.png"}
              alt="logo"
            />
          </div>
          <div className={"MoNavSocial"}>
            <ul>{socialLinks}</ul>
          </div>
        </div>
        <div
          className={"MoNavLinks"}
          ref={(el: any) => {
            nav = el;
          }}
        >
          <ul>
            {navLinks.map((link: any, i: number) => (
              <li key={i}>
                <a href={"#" + link.target} onClick={handelExpand}>
                  {link.label}
                </a>
              </li>
            ))}
          </ul>
          {width < 700 ? (
            <div className={"mobileNavII"}>
              <div className={"MoNavSocialII"}>
                <ul>{socialLinks}</ul>
              </div>
            </div>
          ) : null}
          <br></br>
        </div>
      </div>
      <div className="height-45"></div>
      <ElementsWrapper items={navLinks}>
        <section id="profile" className="item profile-height">
          <Profile />
        </section>
        <section id="work" className="item work-height">
          <Work />
        </section>
        <section id="projects" className="item project-height">
          <Projects />
        </section>
        <section id="skills" className="item skill-height">
          <Skills />
        </section>
        <section id="certificates" className="item certficates-height">
          <MobileCertificates />
        </section>
      </ElementsWrapper>
      <Footer></Footer>
    </div>
  );
};

export default MobileNav;
