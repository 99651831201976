import { createTheme, ThemeProvider } from "@mui/material/styles";
import ReactDOM from "react-dom/client";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import { faLinkedinIn } from "@fortawesome/free-brands-svg-icons";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { HashRouter as Router } from "react-router-dom";
import { ReactNavbar } from "./navbar";
import i18n from "i18next";
import { initReactI18next, useTranslation } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import Backend from "i18next-http-backend";

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    debug: false,
    lng: "en",
    fallbackLng: "en",
    interpolation: {
      escapeValue: false, // React already escapes by default
    },
  });
export default i18n;

const theme = createTheme({
  typography: {
    fontFamily: [
      "San Francisco", // You can change this to your desired font family
      "Arial", // Fallback font
      "-apple-system", // Fallback font for macOS/iOS
      "sans-serif", // Generic fallback
    ].join(","),
  },
});

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

function NavigationMenu() {
  const { t } = useTranslation();

  const menuItems = [
    { label: t("profile").toUpperCase(), to: "/profile", target: "profile" },
    { label: t("work").toUpperCase(), to: "/work", target: "work" },
    { label: t("projects").toUpperCase(), to: "/projects", target: "projects" },
    { label: t("skills").toUpperCase(), to: "/skills", target: "skills" },
    {
      label: t("certificates").toUpperCase(),
      to: "/certificates",
      target: "certificates",
    },
  ];

  return (
    <ReactNavbar
      color="rgb(0, 0, 0)"
      menu={menuItems}
      social={[
        {
          name: "Linkedin",
          url: "https://www.linkedin.com/in/marvin-baumgart-a69715b3/",
          icon: faLinkedinIn,
        },
        {
          name: "Mail",
          url: "mailto:marvinbaumgart18@gmail.com",
          icon: faEnvelope,
        },
      ]}
      sticky={true}
    />
  );
}

root.render(
  <ThemeProvider theme={theme}>
    <Router>
      <NavigationMenu/>
    </Router>
  </ThemeProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
