import React, { useState, useEffect } from "react";
import { Controller, Scene } from "react-scrollmagic";
import { Tween, Timeline } from "react-gsap";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { ReactNavbarProps } from "./types";
import "./styles/style.css";
import Skills from "../content/skills";
import MobileNav from "./mobilenav";
import ElementsWrapper from "./menu/elementwrapper";
import Menu from "./menu/menu";
import Work from "../content/work";
import Certificates from "../content/certificates";
import Profile from "../content/profile";
import Projects from "../content/projects";
import Footer from "../content/footer";
import "/node_modules/flag-icons/css/flag-icons.min.css";
import LanguageSwitcher from "../languageswitch";

const containerStyle = {
  width: "100%",
  margin: "0px 0 100px",
  backgroundColor: "#f5f5f7",
};

export const ReactNavbar: React.FC<ReactNavbarProps> = ({
  color,
  menu,
  social,
  sticky,
}) => {
  const [width, setWidth] = useState(window.innerWidth);
  const updateWidthAndHeight = () => {
    setWidth(window.innerWidth);
  };
  useEffect(() => {
    window.addEventListener("resize", updateWidthAndHeight);
    return () => window.removeEventListener("resize", updateWidthAndHeight);
  });

  if (width <= 1240) {
    return (
      <MobileNav
        width={width}
        background={color}
        navLinks={menu}
        socialIcons={social}
        sticky={sticky}
      />
    );
  }

  return (
    <div style={{ minHeight: "100vh", width: "100%" }}>
      <Controller>
        <Scene triggerHook="onLeave" duration={300} pin>
          {(progress: any) => (
            <Timeline totalProgress={progress} paused>
              <Tween
                from={{ height: "150px" }}
                to={{ height: "80px", background: color }}
              >
                <div className={"header"}>
                  <div className={"navLogo"}>
                    <Link to="/">
                      <div className="logo-container">
                        <Timeline totalProgress={progress} paused>
                          <Tween
                            from={{ height: "100px", filter: "invert(0%)" }}
                            to={{ height: "50px", filter: "invert(100%)" }}
                          >
                            <img src={"logo192.png"} alt="logo" />
                          </Tween>
                        </Timeline>
                      </div>
                    </Link>
                  </div>

                  {progress > 0.5 && (
                    <Menu
                      items={menu}
                      offset={-80}
                      duration={500}
                      delay={0}
                    ></Menu>
                  )}

                  <div className={"navSocial"}>
                    <ul>
                      {social.map((icon, i) => (
                        <Timeline key={i} totalProgress={progress} paused>
                          <Tween
                            from={{ color: "black", borderColor: "black" }}
                            to={{ color: "white", borderColor: "white" }}
                          >
                            <li>
                              <a
                                target="_blank"
                                href={icon.url}
                                rel="noopener noreferrer"
                              >
                                <Tween
                                  from={{
                                    color: "black",
                                    borderColor: "black",
                                  }}
                                  to={{ color: "white", borderColor: "white" }}
                                >
                                  <FontAwesomeIcon icon={icon.icon} />
                                </Tween>
                              </a>
                              <span className={"tooltiptext"}>{icon.name}</span>
                            </li>
                          </Tween>
                        </Timeline>
                      ))}
                      <Timeline totalProgress={progress} paused>
                        <Tween
                          from={{ color: "black", borderColor: "black" }}
                          to={{ color: "white", borderColor: "white" }}
                        >
                          <li>
                            <Tween
                              from={{
                                color: "black",
                                borderColor: "black",
                              }}
                              to={{ color: "white", borderColor: "white" }}
                            >
                              <LanguageSwitcher></LanguageSwitcher> 
                            </Tween>
                          </li>
                        </Tween>
                      </Timeline>
                    </ul>
                  </div>
                </div>
              </Tween>
            </Timeline>
          )}
        </Scene>
      </Controller>                              
      <div style={containerStyle}>
        <ElementsWrapper items={menu}>
          <section id="profile" className="item height-1000">
            <Profile />
          </section>
          <section id="work" className="item height-1000">
            <Work />
          </section>
          <section id="projects" className="item height-1000">
            <Projects />
          </section>
          <section id="skills" className="item height-1000">
            <Skills />
          </section>
          <section id="certificates" className="item">
            <Certificates />
          </section>
        </ElementsWrapper>
      </div>
      <Footer></Footer>
    </div>
  );
};
