import React, { useState } from "react";
import { useTranslation } from "react-i18next";

const LanguageSwitcher = () => {
  const { i18n } = useTranslation();
  const [langu, setLangu] = useState("en");

  const toggleLanguage = () => {
    const newLanguage = langu === "en" ? "de" : "en";
    i18n.changeLanguage(newLanguage);
    setLangu(newLanguage);
  };

  return (
    <span
      onClick={() => toggleLanguage()}
      className={langu === "en" ? "fi fi-de" : "fi fi-gb"}
    ></span>
  );
};

export default LanguageSwitcher;
