import React from "react";
import { Link } from "react-scroll";

import { MenuNavProps } from "../types";


const MenuNav: React.FC<MenuNavProps> = ({
  index,
  item,
  offset,
  duration,
  delay,
  moveNavs,
  width,
  linkClass,
  activeLinkClass,
}) => {
  const liStyle: React.CSSProperties = {
    width: width,
    height: "100%",
  };

  return (
    <li style={liStyle}>
      <Link
        className={linkClass}
        activeClass={activeLinkClass}
        onSetActive={() => {
          moveNavs && moveNavs(index, width!);
        }}
        to={item.target}
        spy={true}
        smooth={true}
        offset={offset}
        duration={duration}
        isDynamic={true}
        delay={delay}
      >
        {item.label}
      </Link>
    </li>
  );
};

export default MenuNav;
