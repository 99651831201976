import React from "react";

import MenuNavs from "./menunavs";
import ElementsWrapper from "./elementwrapper";
import "../styles/style.css";
import { MenuProps } from "../types";


const Menu: React.FC<MenuProps> = ({
  items,
  offset,
  duration,
  delay,
  children,
  coverWidth,
  navWidth,
  linkClass,
  activeLinkClass,
}) => {
  const navbarStyle: React.CSSProperties = {
    height: "100%",
    display: "flex",
    justifyContent: "center",
  };

  offset = offset ? offset : -80;
  duration = duration ? duration : 500;
  delay = delay ? delay : 0;

  return (
    <div style={navbarStyle}>
      <MenuNavs
        items={items}
        offset={offset}
        duration={duration}
        delay={delay}
        coverWidth={coverWidth}
        navWidth={navWidth}
        linkClass={linkClass ? linkClass : "link"}
        activeLinkClass={activeLinkClass ? activeLinkClass : "active_link"}
      />
      {children}
    </div>
  );
};

export default Menu;

export { ElementsWrapper };
