import { useTranslation } from "react-i18next";
import "./style.css";

function MobileCertificates() {
  const { t } = useTranslation();
  
  return (
    <div className={"content"}>
      <h1 className={"content-header"}>{t('certificates')}</h1>
      <hr className={"content-underline"} color="#CECED2"></hr>
      <div>
        <div className="box margin-top-50">
          <div className="grid-container-education">
            <div className="grid-item-education text-align-left">
              05/2016 - 08/2016
            </div>
            <div className="grid-item-education">
              Machine Learning Course
              <br></br>
              STANDFORD UNIVERSITY, [ONLINE] (USA)
            </div>
            <div className="grid-item-education text-align-left">
              01/2018 - 07/2018
            </div>
            <div className="grid-item-education">
              Deep Learning Nano Degree
              <br></br>
              UDACITY, [ONLINE] (USA)
            </div>
            <div className="grid-item-education text-align-left">
              05/2022 - 05/2022
            </div>
            <div className="grid-item-education">
              iSAQB Certified Professional for Software Architecture -
              Foundation Level (DE)
            </div>
            <div className="grid-item-education text-align-left">TODO</div>
              <div className="grid-item-education">
                TODO
                <br></br>
                TODO
              </div>
              <div className="grid-item-education text-align-left">TODO</div>
              <div className="grid-item-education">
                TODO
                <br></br>
                TODO
              </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MobileCertificates;
