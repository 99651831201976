import { useTranslation } from 'react-i18next';
import "./style.css";

function Projects() {
  const { t } = useTranslation();

  return (
    <div className={"content"}>
      <h1 className={"content-header"}>{t('projects')}</h1>
      <hr className={"content-underline"} color="#CECED2"></hr>
      <div className="container gap-125">
        <div className="box margin-top-50 text-content-card-height">
          <div className="description-box">
            <p className="description-box-date">5.5.2023 – TODAY</p>
            <span className="description-box-header">
              Online, Berlin
            </span>
            <h4 className="description-box-title">{t('hauschgart.title')}</h4>
            <p className="description-box-text no-margin">
              {t('hauschgart.description')}
            </p>
          </div>
        </div>
        <div className="box margin-top-50 text-content-card-height mobile-margin-top">
          <div className="description-box">
            <p className="description-box-date">12.2018 – 04.2021</p>
            <span className="description-box-header">
              - 
            </span>
            <h4 className="description-box-title">{t('trincom.title')}</h4>
            <p className="description-box-text no-margin">
              {t('trincom.description')}
            </p>
          </div>
          <div className="description-box margin-top-50 mobile-margin-top">
            <p className="description-box-date">11.2019 – 08.2020</p>
            <span className="description-box-header">
              Am Steinbruch 1, 87545 Burgberg im Allgäu
            </span>
            <h4 className="description-box-title">{t('sesitec.title')}</h4>
            <p className="description-box-text no-margin">
              {t('sesitec.description')}
              <br></br>
              <a className="button-link" target="_blank" rel="noreferrer" href="http://waterskiparks.com/configurator/index.html">
                http://waterskiparks.com/configurator
              </a>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Projects;
