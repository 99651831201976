import React from "react";

import { Element } from "react-scroll";
import { ElementWrapperProps } from "../types";


const ElementsWrapper: React.FC<ElementWrapperProps> = ({
  children,
  items,
  style,
  className,
}) => (
  <div>
    {React.Children.map(children, (child, i) => (
      <Element
        style={style}
        className={className}
        name={items[i]?.target}
        key={i}
      >
        {child}
      </Element>
    ))}
  </div>
);

export default ElementsWrapper;
