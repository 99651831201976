import React, { useRef } from "react";

import Menu from "./menunav";
import { NAV_WIDTH } from "../constants";
import { MenuNavsProps } from "../types";


const MenuNavs: React.FC<MenuNavsProps> = ({
  items,
  offset,
  duration,
  delay,
  coverWidth,
  navWidth,
  linkClass,
  activeLinkClass,
}) => {
  const navsNode = useRef<HTMLUListElement>(null);

  let currIndex = null;
  let nextIndex = 0;

  navWidth = navWidth ? navWidth : NAV_WIDTH;
  coverWidth = coverWidth ? coverWidth : items.length * navWidth;

  const coverStyle: React.CSSProperties = {
    width: 910, // coverWidth
    height: "100%",
    overflow: "hidden",
    WebkitMaskBoxImage:
      "-webkit-linear-gradient(left, transparent, white 50%, transparent)",
  };

  const navsStyle: React.CSSProperties = {
    margin: 0,
    left: coverWidth / 2 - 43,
    paddingLeft: 0,
    position: "relative",
    height: "100%",
    display: "flex",
    listStyle: "none",
  };

  const moveNavs = (newIndex: number, navWidth: number) => {
    currIndex = nextIndex;
    nextIndex = newIndex;

    if (navsNode.current) {
      let currLeft = Number.parseInt(navsNode.current.style.left!);
      const indexOffset = nextIndex - currIndex;
      navsNode.current.style.left = currLeft - indexOffset * navWidth + "px";
    }
  };

  return (
    <div style={coverStyle}>
      <ul style={navsStyle} ref={navsNode}>
        {items.map((item, i) => (
          <Menu
            key={i}
            index={i}
            item={item}
            offset={offset}
            duration={duration}
            delay={delay}
            moveNavs={moveNavs}
            width={navWidth}
            linkClass={linkClass}
            activeLinkClass={activeLinkClass}
          />
        ))}
      </ul>
    </div>
  );
};

export default MenuNavs;
