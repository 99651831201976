import "./style.css";
import SingleSkill from "./singleskill";
import { useTranslation } from "react-i18next";

function Skills() {
  const { t } = useTranslation();
  
  return (
    <div className={"content"}>
      <h1 className={"content-header"}>Skills</h1>
      <hr className={"content-underline"} color="#CECED2"></hr>
      <div className="container gap-125 skill-container">
        <div className="box margin-top-50 text-content-card-height">
          <h4 className="description-box-title">{t('languageSkills')}</h4>
          <SingleSkill skillName={t('german')} starCount={5}></SingleSkill>
          <SingleSkill skillName={t('english')} starCount={5}></SingleSkill>
          <SingleSkill skillName={t('dutch')} starCount={2}></SingleSkill>
          <div className="margin-top-25">
            <h4 className="description-box-title">{t('itSkills.title')}</h4>
            <SingleSkill skillName={"SQL"} starCount={5}></SingleSkill>
            <SingleSkill skillName={"Java"} starCount={5}></SingleSkill>
            <SingleSkill skillName={"C#"} starCount={5}></SingleSkill>
            <SingleSkill skillName={"Go"} starCount={4.5}></SingleSkill>
            <SingleSkill skillName={"C++"} starCount={4}></SingleSkill>
            <SingleSkill skillName={"git (bash)"} starCount={4}></SingleSkill>
            <SingleSkill skillName={"JavaScript/Typescript"} starCount={4}></SingleSkill>
            <SingleSkill skillName={"HTML, CSS"} starCount={3}></SingleSkill>
            <SingleSkill skillName={"Python"} starCount={3}></SingleSkill>
            <SingleSkill skillName={"Lucene"} starCount={3}></SingleSkill>
            <SingleSkill skillName={"Linux (bash)"} starCount={3}></SingleSkill>
            <SingleSkill skillName={"R"} starCount={2}></SingleSkill>
            <SingleSkill skillName={"PHP"} starCount={1.5}></SingleSkill>
          </div>
        </div>
        <div className="box margin-top-50 text-content-card-height mobile-margin-top">
          <h4 className="description-box-title">{t('softSkills')}</h4>
          <SingleSkill skillName={t('creativity')} starCount={5}></SingleSkill>
          <SingleSkill skillName={t('criticalThinking')} starCount={4}></SingleSkill>
          <SingleSkill skillName={t('leadership')} starCount={4}></SingleSkill>
          <SingleSkill skillName={t('adaptability')} starCount={4}></SingleSkill>
          <SingleSkill skillName={t('communication')} starCount={3.5}></SingleSkill>
          <div className="margin-top-25">
            <h4 className="description-box-title">{t('itSkillsFrameworks')}</h4>
            <SingleSkill skillName={"Spring Boot"} starCount={5}></SingleSkill>
            <SingleSkill skillName={"Unity3D"} starCount={5}></SingleSkill>
            <SingleSkill skillName={"Scrum"} starCount={4.5}></SingleSkill>
            <SingleSkill skillName={"PostgreSQL, HANA DB"} starCount={4}></SingleSkill>
            <SingleSkill skillName={"Mongo DB"} starCount={4}></SingleSkill>
            <SingleSkill skillName={"AFL"} starCount={4}></SingleSkill>
            <SingleSkill skillName={"NodeJS"} starCount={4}></SingleSkill>
            <SingleSkill skillName={"Neo4J"} starCount={3.5}></SingleSkill>
            <SingleSkill skillName={"Docker"} starCount={3.5}></SingleSkill>
            <SingleSkill skillName={"Jupyter Notebooks"} starCount={3.5}></SingleSkill>
            <SingleSkill skillName={"HANA Cloud Platform"} starCount={3}></SingleSkill>
            <SingleSkill skillName={"Angular"} starCount={3}></SingleSkill>
            <SingleSkill skillName={"SAP FIORI / UI5"} starCount={3}></SingleSkill>
            <SingleSkill skillName={"Google Cloud"} starCount={3}></SingleSkill>
            <SingleSkill skillName={"Ionic"} starCount={3}></SingleSkill>
            <SingleSkill skillName={"Heroku"} starCount={2}></SingleSkill>
            <SingleSkill skillName={"Apache Kafka"} starCount={2}></SingleSkill>
            <SingleSkill skillName={"LaTeX"} starCount={2}></SingleSkill>
            <SingleSkill skillName={"Oracle"} starCount={1.5}></SingleSkill>
          </div>
        </div>
      </div>     
    </div>
  );
}

export default Skills;